/*
  Project: Traveling Tiki
  Author: GoodieWebsite
 */

import 'lazysizes';
import datepicker from 'js-datepicker';

datepicker('.js-date');
datepicker('.js-date2');
